import React from 'react';
import { ROUTES } from '../constants';
import DeepDiveLogo from './DeepDiveLogo';

const HeaderNavigation = () => {
  return (
    <div className="row justify-content-between">
      <div className={'col-6'}>
        <a href={ROUTES.home}>
          <DeepDiveLogo/>
        </a>
      </div>
      <div className={'col-6'}>
        <nav>
          <ul>
            <li className='nav-item'>
              <a
                href={ROUTES.pricing}
                rel="noreferrer"
              >
                Pricing
              </a>
            </li>
            <li className='nav-item'>
              <a
                href="https://app.deepdive360.com/app/signup/"
                target="_blank"
                rel="noreferrer"
              >
                Sign Up
              </a>
            </li>
            <li className='nav-item'>
              <a
                href="https://app.deepdive360.com/app/login/"
                target="_blank"
                rel="noreferrer"
              >
                Login
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default HeaderNavigation;
