import React from 'react';

const GetStarted = ({ text='Get Started', primary=true }) => (
  <a
    href='https://app.deepdive360.com/app/signup/'
    target="_blank"
    rel="noreferrer"
    className={`btn ${primary ? 'btn-primary primary-button' : 'secondary-button'}`}
  >
    {text}
  </a>
);

export default GetStarted;
