export const ROUTES = {
  home: '/',
  pricing: '/pricing/',
};

export const PRICING_PLANS = {
    trial: 'trial',
    evaluation: 'evaluation',
    engagement: 'engagement',
    development: 'development',
};