import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../templates/Layout';
import Seo from '../components/Seo';
import GetStarted from '../components/GetStarted';

const IndexPage = () => (
  <Layout>
    <Seo title="DeepDive 360" />

    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-6 m-auto">
            <h2>Employee Evaluations that Drive Results</h2>
            <p>
              Hundreds of organizations are switching to digital employee evaluations to help them
              save time and get the best analytical tools for any occasion.
            </p>

            <ul>
              <li className='checkmark'>360 Yearly Reviews</li>
              <li className='checkmark'>Peer Reviews</li>
              <li className='checkmark'>Self Assessments</li>
              <li className='checkmark'>1 on 1 Meetings</li>
            </ul>
          </div>
          <div className="col-md-6 image-area">
            <StaticImage src="../images/img-review.png" alt="Review" />
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="container word-banner">
        <div className="row content-area">
          <div className="col-md-4">
            <div className="featured-content">
              <span>More Productive</span>
              <span className="amount">1.9x</span>
            </div>
          </div>
          <div className="col-md-8">
            <p>
              Research has shown that with more frequent and better feedback, employees have nearly
              doubled their productivity and have been more engaged.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="overlay-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-6 image-area">
            <StaticImage src="../images/img-analyze.png" alt="Analyze" />
          </div>
          <div className="col-md-6">
            <h2>Analyze Results & Learn Where to Improve</h2>
            <p>
              Hundreds of organizations are switching to digital employee evaluations to help them
              save time and get the best analytical tools for any occasion.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="cta-form-area col-md-8 offset-md-2 text-md-center">
            <h2>Interested?</h2>
          </div>
        </div>

        <div className="row justify-content-center">
          <GetStarted />
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
