import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import GetStarted from './GetStarted';
import HeaderNavigation from './HeaderNavigation';

const Header = () => (
  <header>
    <div className="container">
      <HeaderNavigation />

      <div className="row">
        <div className="col-md-8 offset-md-2 text-md-center">
          <h1>High Performing Companies Start with Feedback</h1>
        </div>
      </div>

      <div className="row justify-content-center">
        <GetStarted />
      </div>

      <div className="row featured-image">
        <div className="col-12">
          <StaticImage src="../images/img-main.png" alt="Main" />
        </div>
      </div>
    </div>
  </header>
);

export default Header;
